import config from 'config/app';
import lib from 'lib/commons';

import {
	CURRENT_USER_REQUEST_ERROR,
	CURRENT_USER_REQUEST_SUCCESS,
	CURRENT_USER_FETCHING,
	SHARE_ERROR,
	SHARE_SUCCESS,
	IS_SHARING,
	SPIN_ERROR,
	SPIN_SUCCESS,
	IS_SPINNING,
	CHECK_MATCH_ERROR,
	CHECK_MATCH_SUCCESS,
	IS_CHECKING_MATCH,
	GET_HISTORY_ERROR,
	GET_HISTORY_SUCCESS,
	IS_GETTING_HISTORY,
	CLAIM_ERROR,
	CLAIM_SUCCESS,
	IS_CLAIMING,
	CLAIM_ERROR_NEW,
	CLAIM_SUCCESS_NEW,
	IS_CLAIMING_NEW,
	REFRESH_ERROR,
	REFRESH_SUCCESS,
	IS_REFRESHING,
	UPDATE_JACKPOT,
	UPDATE_WINNER_JACKPOT,
	UPDATE_WINNER_SEMI_JACKPOT,
	// CLAIMING_CLUB,
	// CLAIM_CLUB_SUCCESS,
	// CLAIM_CLUB_ERROR,
} from './actions';

const convertDateForIos = (date) => {
	var arr = date.split(/[- :]/);
	date = new Date(arr[0], arr[1] - 1, arr[2], arr[3], arr[4], arr[5]);
	return date;
};

const initialState = {
	loading: false,
	login: false,
	user: {},
	currentDatetime: null,
	eventStarted: 0,
	spinAccumulations: [],
	spinSettings: [],
	userHistory: {},
	jackpotValue: 0,
	playNormal: 0,
	winnerJackpot: {},
	winnerSemiJackpot: {},
	delta: 0,
	socket_account_id: null,
	special_date: false,
	socket_env: null,
	// clubSpinsClaimed: 0,

	isSharing: false,
	isCheckingMatch: false,
	isGettingHistory: false,
	isSpinning: false,
	isClaiming: false,
	isClaimingNew: false,
	isRefreshing: false,
	isClaimingClub: false,
};

export default function currentUserReducer(
	state = initialState,
	{ type, payload } = action
) {
	switch (type) {
		case CURRENT_USER_REQUEST_SUCCESS:
			return {
				...state,
				loading: false,
				login: true,
				user: payload.user,
				eventStarted: payload.event_started,
				spinAccumulations: payload.spin_accumulations,
				spinSettings: payload.spin_settings,
				jackpotValue: payload.jackpot_value,
				currentDatetime: payload.current_datetime,
				socket_account_id: payload.socket_account_id,
				winnerJackpot: payload.jackpot_billboard,
				winnerSemiJackpot: payload.mini_jackpot_billboard,
				special_date: payload.special_date,
				socket_env: payload.socket_env,
				delta:
					new Date(convertDateForIos(payload.current_datetime)).getTime() -
					new Date().getTime(),
				errorGlobal: '',
			};
			break;
		case CURRENT_USER_REQUEST_ERROR:
			return {
				...state,
				loading: false,
				eventStarted: payload.event_started,
				spinAccumulations: payload.spin_accumulations,
				spinSettings: payload.spin_settings,
				jackpotValue: payload.jackpot_value,
				currentDatetime: payload.current_datetime,
				socket_account_id: payload.socket_account_id,
				winnerJackpot: payload.jackpot_billboard,
				winnerSemiJackpot: payload.mini_jackpot_billboard,
				special_date: payload.special_date,
				socket_env: payload.socket_env,
				delta:
					new Date(convertDateForIos(payload.current_datetime)).getTime() -
					new Date().getTime(),
				errorGlobal: 'Không có thông tin user',
			};
			break;
		case CURRENT_USER_FETCHING:
			return {
				...state,
				loading: true,
			};
			break;

		//SHARE
		case SHARE_SUCCESS:
			return {
				...state,
				user: {
					...state.user,
					free_spin: payload.user.free_spin,
				},
				isSharing: false,
			};
			break;
		case SHARE_ERROR:
			return {
				...state,
				isSharing: false,
			};
			break;
		case IS_SHARING:
			return {
				...state,
				isSharing: true,
			};
			break;

		//CHECK MATCH
		case CHECK_MATCH_SUCCESS:
			return {
				...state,
				user: {
					...state.user,
					free_spin: payload.user.free_spin,
					playNormal: payload?.play_normal,
				},
				playNormal: payload?.play_normal,
				isCheckingMatch: false,
			};
			break;
		case CHECK_MATCH_ERROR:
			return {
				...state,
				isCheckingMatch: false,
			};
			break;
		case IS_CHECKING_MATCH:
			return {
				...state,
				isCheckingMatch: true,
			};
			break;

		//SPIN
		case SPIN_SUCCESS:
			return {
				...state,
				user: {
					...state.user,
					free_spin: payload.user.free_spin,
					fc: payload.user.fc,
					mc: payload.user.mc,
					accumulation: payload.user.accumulation,
					price_type: payload.user.price_type,
					special_spins: payload.user.special_spins,
					spin_accumulation_reward_history:
						payload.user.spin_accumulation_reward_history,
				},
				// jackpotValue: payload?.jackpot_value,
				isSpinning: false,
			};
			break;
		case SPIN_ERROR:
			return {
				...state,
				isSpinning: false,
			};
			break;
		case IS_SPINNING:
			return {
				...state,
				isSpinning: true,
			};
			break;

		//GET HISTORY
		case GET_HISTORY_SUCCESS:
			return {
				...state,
				userHistory: payload,
				isGettingHistory: false,
			};
			break;
		case GET_HISTORY_ERROR:
			return {
				...state,
				isGettingHistory: false,
			};
			break;
		case IS_GETTING_HISTORY:
			return {
				...state,
				isGettingHistory: true,
			};
			break;

		// CLAIM
		case CLAIM_SUCCESS:
			return {
				...state,
				user: {
					...state.user,
					spin_accumulation_reward_history:
						payload.user.spin_accumulation_reward_history,
				},
				isClaiming: false,
			};
			break;
		case CLAIM_ERROR:
			return {
				...state,
				isClaiming: false,
			};
			break;
		case IS_CLAIMING:
			return {
				...state,
				isClaiming: true,
			};
			break;
		//CLAIMING NEW
		case CLAIM_SUCCESS_NEW:
			return {
				...state,
				user: {
					...state.user,
					spin_accumulation_reward_history:
						payload.user.spin_accumulation_reward_history,
				},
				isClaimingNew: false,
			};
			break;
		case CLAIM_ERROR_NEW:
			return {
				...state,
				isClaimingNew: false,
			};
			break;
		case IS_CLAIMING_NEW:
			return {
				...state,
				isClaimingNew: true,
			};
			break;
			//REFRESH
		case REFRESH_SUCCESS:
			return {
				...state,
				user: {
					...state.user,
					fc: payload.user.fc,
					mc: payload.user.mc,
				},
				isRefreshing: false,
			};
			break;
		case REFRESH_ERROR:
			return {
				...state,
				isRefreshing: false,
			};
			break;
		case IS_REFRESHING:

		//CLAIM CLUB
		// case CLAIM_CLUB_SUCCESS:
		// 	return {
		// 		...state,
		// 		user: {
		// 			...state.user,
		// 			club_spins_claimed: payload.user.club_spins_claimed,
		// 			free_spin: payload.user.free_spin,
		// 		},
		// 		isClaimingClub: false,
		// 	};
		// 	break;
		// case CLAIM_CLUB_ERROR:
		// 	return {
		// 		...state,
		// 		isClaimingClub: false,
		// 	};
		// 	break;
		// case CLAIMING_CLUB:
		// 	return {
		// 		...state,
		// 		isClaimingClub: true,
		// 	};
		// 	break;

		//UPDATE JACKPOT
		case UPDATE_JACKPOT:
			return {
				...state,
				jackpotValue: payload,
			};
			break;

		//UPDATE WINNER JACKPOT
		case UPDATE_WINNER_JACKPOT:
			return {
				...state,
				winnerJackpot: payload,
			};
			break;

		//UPDATE WINNER SEMI JACKPOT
		case UPDATE_WINNER_SEMI_JACKPOT:
			return {
				...state,
				winnerSemiJackpot: payload,
			};
			break;

		default:
			return state;
	}
}
